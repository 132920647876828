import { KeyValueBoolean } from './../types/types';

export const EBIA_BREAK_POINTS: KeyValueBoolean = {
  s320px: false,
  s375px: false,
  s414px: false,
  s640px: false,
  s768px: false,
  s1024px: false,
  s1440px: false,
  s1920px: false,
};
