import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content-block',
  templateUrl: './content-block.component.html',
  styleUrls: ['./content-block.component.scss'],
})
export class ContentBlockComponent implements OnInit {
  @Input() debugging = false;
  @Input() title2 = 'title2 empty';
  @Input() data: Array<any>;
  @Input() useData2 = false;

  constructor() {}

  ngOnInit() {}
}
