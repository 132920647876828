import { Component, OnInit, Input } from '@angular/core';

const styleUrl = '../../styles.scss';

@Component({
  selector: 'app-data1',
  template: `
    <div *ngFor="let dm of data">
      <div *ngFor="let d of dm; first as first; index as i">
        <span [ngClass]="{ 'cSmall cTab1': !first, cGray: i > 1 }">
          {{ d }}
        </span>
      </div>
    </div>
  `,
  styleUrls: [styleUrl],
})
export class Data1Component implements OnInit {
  @Input() data: Array<any>;
  constructor() {}

  ngOnInit() {}
}
