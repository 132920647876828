import { ClientInfoService } from './../../shared/services/client-info.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() debugging = false;
  @Output() op = new EventEmitter<any>();

  public ci: any = {
    screen: {},
    language: '',
  };

  constructor(private ws: ClientInfoService) {}

  ngOnInit() {
    this.ci.screen = this.ws.get(['screen']);
    this.ci.language = this.ws.get(['navigator', 'language']);
  }

  toggleDebugFlag() {
    this.op.emit({ action: 'TOGGLE_DEBUG_FLAG' });
  }
}
