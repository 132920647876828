import { KeyValueBoolean } from './../shared/types/types';
import { EBIA_BREAK_POINTS } from './../shared/static/static';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  BreakpointObserver,
  BreakpointState,
} from '@angular/cdk/layout';
import { ClientInfoService } from './../shared/services/client-info.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public bp: KeyValueBoolean = { ...EBIA_BREAK_POINTS };
  public bpo: Subscription;

  public debugging = false;

  constructor(
    private bo: BreakpointObserver,
    private ws: ClientInfoService
  ) {}

  ngOnInit() {
    this.resetBreakPoints();
    this.setBreakPointRelatedValues();
  }

  toggleDebugFlag(event: any) {
    this.debugging = !this.debugging;
    console.log('TOGGLE EVENT: ', event);
  }

  resetBreakPoints() {
    Object.keys(this.bp).map((key) => {
      this.bp[key] = false;
    });
  }

  setBreakPointRelatedValues() {
    this.bpo = this.bo
      .observe([
        '(max-width: 320px)',
        '(min-width: 321px) and (max-width: 375px)',
        '(min-width: 376px) and (max-width: 414px)',
        '(min-width: 415px) and (max-width: 640px)',
        '(min-width: 641px) and (max-width: 768px)',
        '(min-width: 769px) and (max-width: 1024px)',
        '(min-width: 1025px) and (max-width: 1440px)',
        '(min-width: 1441px) and (max-width: 1920px)',
      ])
      .subscribe((state: BreakpointState) => {
        const maxWidth = this.getMatchedBreakPoint(state);
        this.setBreakPointFlag('s' + maxWidth);
        console.log('BP: ', this.bp);
      });
  }

  getMatchedBreakPoint(state: BreakpointState) {
    console.log('STATE: ', state);
    let maxWidth = '';
    const breakpoints = state.breakpoints;
    Object.keys(breakpoints).map((key: any) => {
      if (breakpoints[key] === true) {
        maxWidth = key.split(' ').pop();
        maxWidth = maxWidth.split(')').shift();
      }
    });
    console.log('Max-Width: ', maxWidth);
    return maxWidth;
  }

  setBreakPointFlag(flag: string) {
    Object.keys(this.bp).map((key: string) => {
      this.bp[key] = key === flag ? true : false;
    });
  }

  ngOnDestroy(): void {
    this.bpo.unsubscribe();
  }
}
