import { Component, OnInit, Input } from '@angular/core';

const styleUrl = '../../styles.scss';

@Component({
  selector: 'app-data2',
  template: `
    <div *ngFor="let dm2 of data">
      <div *ngFor="let dm1 of dm2; first as f; index as j">
        <div
          *ngFor="let d of dm1; first as first; index as i"
          [ngClass]="{
            'cGray cSmall': j === 0,
            cInline: j === 0 && (i === 0 || i === 1),
            cAccent: i === 1
          }"
        >
          <span
            [ngClass]="{
              cSmall: !first,
              cTab1: j > 0 || (i > 0 && i < 2),
              cGray: i >= 2,
              cTab2: i > 0
            }"
          >
            {{ d }}
          </span>
        </div>
      </div>
    </div>
  `,
  styleUrls: [styleUrl],
})
export class Data2Component implements OnInit {
  @Input() data: Array<any>;
  constructor() {}

  ngOnInit() {}
}
