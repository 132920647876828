import {
  ACADEMIC_CAREER,
  AWARDS,
  ROLES,
  PROFESSIONAL_CAREER_10,
  PROFESSIONAL_CAREER_20,
  TECHNOLOGIES,
  SUMMARY,
  INTERESTS,
  ARCHITECTURE,
  PRODUCT_DESIGN,
} from './../../shared/data/eb-cv.data';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {
  @Input() debugging = false;

  public academicCareer: Array<any> = ACADEMIC_CAREER;
  public awards: Array<any> = AWARDS;
  public roles: Array<any> = ROLES;
  public professionalCareer10 = PROFESSIONAL_CAREER_10;
  public professionalCareer20 = PROFESSIONAL_CAREER_20;
  public technologies = TECHNOLOGIES;
  public summary = SUMMARY;
  public interests = INTERESTS;
  public architecture = ARCHITECTURE;
  public productDesign = PRODUCT_DESIGN;

  public dummy: Array<any>;

  constructor() {}

  ngOnInit() {
    this.dummy = Array.from(Array(3)).map((e: any, i: number) => ++i);
  }
}
