import { Component, OnInit, Input } from '@angular/core';

const styleUrl = '../../styles.scss';

@Component({
  selector: 'app-title2',
  template: `
    <div
      class="cTitle2"
      [ngClass]="{
        cMTop2: mT,
        cDebugging: debugging
      }"
    >
      {{ t }}
    </div>
    <hr class="cDivider" />
  `,
  styleUrls: [styleUrl],
})
export class Title2Component implements OnInit {
  @Input() debugging = false;
  @Input() mT = false;
  @Input() t = 'CT2 EMPTY';
  constructor() {}

  ngOnInit() {}
}
