import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutModule } from '@angular/cdk/layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './dashboard/header/header.component';
import { PersonalComponent } from './dashboard/personal/personal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MatDividerModule,
  MatButtonModule,
  MatSliderModule,
} from '@angular/material';
import { ContentComponent } from './dashboard/content/content.component';
import { Title2Component } from './templates/title2.component';
import { FooterComponent } from './dashboard/footer/footer.component';
import { ContentBlockComponent } from './dashboard/content-block/content-block.component';
import { Data1Component } from './templates/data1.component';
import { Data2Component } from './templates/data2.component';

const MatModule = [
  MatDividerModule,
  MatButtonModule,
  MatSliderModule,
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    PersonalComponent,
    ContentComponent,
    Title2Component,
    FooterComponent,
    ContentBlockComponent,
    Data1Component,
    Data2Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    LayoutModule,
    BrowserAnimationsModule,
    MatModule,
  ],
  providers: [
    { provide: 'MY_CLIENT_INFO', useFactory: getClientInfo },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function getClientInfo() {
  return window ? window : {};
}
