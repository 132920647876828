import { Study } from "../types/types";

export const personalData = {
  name: {
    title_before: "Ing.",
    first_name: "Erkan",
    last_name: "Buelbuel",
    title_after: "BSc.",
  },
};

export const ACADEMIC_CAREER = [
  [
    "University of Vienna",
    "Media Computer Science",
    "Graduation 2013 in Vienna (A-1010)",
    "(Extra-occupational Study)",
  ],
  [
    "HTL (Higher Technical Institute)",
    "Technical Computer Science",
    "Graduation 1999 in Vienna (A-1010)",
  ],
  ["Apprenticeship", "Designer for Engineering Industries"],
];

export const AWARDS = [
  [
    "2010",
    "Third best Student - University of Vienna",
    "1.2 Grade point average",
  ],
];

export const ROLES = [
  ["Chief Software Architect"],
  ["Lead/Senior Frontend Developer"],
  [
    "Project & Product Manager",
    "Scrum Master & Product Owner (Certified)",
    "User Interface/Experience Designer, UX/UI",
  ],
];

export const PROFESSIONAL_CAREER_10 = [
  [
    ["SimplyVision GmbH", "HR Company"],

    [
      "IBM - Deutsche Bahn",
      "AI Annotation Tools & Visualisations",
      "Senior Fullstack Developer Architect",
      "2019/12 - 2021/01, Frankfurt (DE)",
    ],

    [
      "ERGO Dusseldorf & MunichRe Munich",
      "Lead Solution Architect ",
      "Senior FE Developer",
      "2018/09 - 2019/11, Dusseldorf & Munich",
    ],

    [
      "IBM - Deutsche Bahn",
      "Chief Software Architect",
      "2018/01 - 2018/09, Frankfurt (DE)",
    ],
  ],

  [
    ["GULP CH", "Intermediary"],
    [
      "Allianz Suisse AG",
      "Software Architect",
      "2017/08 - 2017/12, Zurich (CH)",
    ],
  ],

  [
    ["Atos IT Solutions", "Intermediary"],

    [
      "Deutsche Bank",
      "Lead Developer Architect",
      "2017/05 - 2017/07, Frankfurt (DE)",
    ],
  ],
  [
    ["SimplyVision GmbH", "HR Company"],
    [
      "Allianz Deutschland AG",
      "Senior/Lead Developer Architect",
      "2017/02 - 2017/05, Stuttgart (DE)",
    ],
  ],
  [
    ["Hays Austria", "Intermediary"],
    [
      "Allianz Austria",
      "Senior Developer Architect",
      "2016/06 - 2017/01, Vienna (AT)",
    ],
  ],
];

export const PROFESSIONAL_CAREER_20 = [
  [
    ["Austin Fraser", "Intermediary"],
    [
      "FNT Software",
      "Senior Web Architect",
      "2016/03 - 2017/06, Ellwangen (DE)",
    ],
  ],
  [
    ["Via XING", "Direct Contact"],
    [
      "Aurena Group",
      "Senior Web Architect & Developer",
      "2015/08 - 2016/02, Linz (AT)",
    ],
  ],
  [
    ["IVM Technical Consultants", "Intermediary"],
    [
      "Siemens ETM Austria & Germany",
      "Senior Web Developer & Usability Expert",
      "2014/07 - 2015/03, Eisenstadt & Karlsruhe",
    ],
  ],
  [
    ["APC Business Services", "Intermediary"],
    [
      "Post AG Austria",
      "Senior Web Developer & Usability Expert",
      "2013/09 - 2014/06, Vienna (AT)",
    ],
  ],

  [
    ["Direct Contact", "Part time"],
    [
      "University of Vienna",
      "Software Engineering & Analysis",
      "2010/10 - 2012/03, Vienna (AT)",
    ],
  ],

  [
    ["Direct Contact", "Part time"],
    [
      "Fabasoft AG Austria",
      "Part Time Senior Software Trainer",
      "2008/02 - Upright Contract, Austria",
    ],
    ["..and many more"],
  ],
];

export const TECHNOLOGIES = [
  [
    ["Frontend/Middleware/..", "Expert Level"],
    ["Angular 2+ (4/5/6/7/8/9/10/..)"],
    ["React 15/16/..", "VueJS 2+"],
    ["NestJS 7+", "ExpressJS 4+", "Fastify 3+"],
  ],
  [
    ["Programming Languages", "Expert Level"],
    ["JavaScript, ES6+"],
    ["TypeScript 3/4/.."],
    ["Python 3+"],
    ["(Go & Rust)"],
  ],
  [
    ["Libraries", "Own Preferences"],
    ["RxJS, Lodash,.."],
    ["D3JS, Highcharts,.."],
  ],
  [
    ["Web/Browser", "Expert Level"],
    ["HTML5, CSS3, SCSS,..", "HTML5-APIS, SocketIo, WebWorkers,.."],
  ],
  [["Source Control", "Expert Level"], ["Git, Git-Flow"]],
  [["Node Environment", "Expert Level"], ["NodeJS, NPM"]],
  [["Operating Systems", "MacOS Evangelist"], ["MacOS, Linux, Windows"]],
  [
    ["Testing Tools", "Unit/Integration/E2E"],
    [
      "Data/TDD/BDD,.. Driven",
      "Jest, Jasmine, Prototype, Selenium",
      "Mocha, Chai, Enzyme",
      "Cypress.io, TestCafe,..",
      "API/Contracts, PactIo,..",
    ],
    ["..and many more"],
  ],
];

export const SUMMARY = [
  [
    ["Management Experience", "10+ years"],
    [
      "High level – Communication Skills",
      "Holistic & Target-Oriented Approach",
    ],

    [
      "State of the Art Web Software",
      "Future proven Architecture",
      "I18N, L16N, A11Y",
    ],

    ["Organization of Agile Teams", "Scrum Methodology (Certified)"],

    [
      "Efficient Workflow Management",
      "Task & Process Chain Optimization",
      "Data/Behavior Driven Development",
    ],
  ],
];

export const INTERESTS = [
  [
    ["In every single free minute", "If available"],
    ["Artificial Intelligence", "Deep Learning, Machine Learning"],
    ["Autonomous Systems", "Self Driving Cars", "Drones, Robotics"],
    [
      "Big Data",
      "Visualization, Forecasts",
      "Probability Calculations",
      "TensorFlowJS, D3JS",
    ],
    ["Algorithms", "Finding best-proven way"],
    ["Lifelong Learning", "Not making the same mistake twice"],
    [
      "Philosophy & History",
      "Human Being, Evolution, Intelligence",
      "Social Behavior",
    ],
    [
      "Natural Sciences",
      "Astronomy, Mathematics, Physics,..",
      "Genetic Engineering, CRISPR,..",
    ],
  ],
  [
    ["Last but not least"],
    ["Perpetual yearning for the oceans"],
    ["..and many more"],
  ],
];

export const PRODUCT_DESIGN = [
  [
    ["Product Management", "Design"],
    ["Software Modelling", "UML", "BPMN,.."],
    [
      "MVP Design",
      "User Experience Design",
      "User Interface Design",
      "Story boards, Wireframes",
      "Mockups, Prototypes",
      "Google Material",
      "Clarity Design (vmware)",
      "Twitter Bootstrap,..",
      "Living Style Guides",
    ],
  ],
];

export const ARCHITECTURE = [
  [
    ["Architecture Experience (Bigdata & beyond)", "10+ years"],
    ["Large-scale Enterprise Web Projects", "Deep Technological Knowledge"],

    [
      "Cloud, Virtualization & Deployments",
      "Azure, AWS, MicroServices,..",
      "OpenShift, Kubernetes",
      "Docker, Vagrant",
    ],

    [
      "Continuous Delivery",
      "DevOps & CI Tools",
      "GitHub, GitLab, Bitbucket",
      "Test Automatization",
    ],
    [
      "High Performance, High availability",
      "Progressive Web Apps",
      "Lazy Loading, Load Balancing",
      "Real time, Concurrency,..",
      "Cloud ready Micro Services",
      "Message Brokers,..",
      "Data, BigData, Clusters,..",
      "SQL, NoSQL",
    ],
    [
      "API Management",
      "GraphQL, RESTFull, HATEOAS",
      "OpenAPI (Swagger)",
      "Front/Backend",
    ],
    ["Persistence", "Client APIs, Session Storage, IndexDB,.."],
    ["..and many more"],
  ],
];
