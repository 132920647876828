import { element } from 'protractor';
import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClientInfoService {
  private clientWindow: any;

  constructor(@Inject('MY_CLIENT_INFO') ci: Window) {
    this.clientWindow = ci ? ci : {};
  }

  get(props?: any) {
    const len = props.length ? props.length : 0;
    let value: any;
    if (len > 0) {
      value = this.clientWindow[props[0]];
      for (let i = 1; i < len; i++) {
        if (value) {
          value = value[props[i]];
        } else {
          return null;
        }
      }
      return value;
    } else {
      return null;
    }
  }

  getCertainProp(p: any) {
    return this.clientWindow[p] ? this.clientWindow[p] : null;
  }
}
